import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// REDU X-MAS
import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';

import ErrorFallback from './components/error-fallback';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { ClearErrors } from './actions/auth';
import reducers from './reducers';

import App from './routes';

import Initializers from './initializers';
import { GAListener } from './components/google-analytics/ga-listener';

import 'sanitize.css/sanitize.css';
import './index.css';
import ScrollToTop from './components/scroll-to-top';
import { init as initSentry } from './utils/sentry';

const initialState = {};
const enhancers = [];
const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line global-require
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'user'],
};
const persistedReducer = persistReducer(persistConfig, reducers());
const store = createStore(persistedReducer, initialState, composedEnhancers);
const persistedStore = persistStore(store);

const onBeforeLift = () => {
    // Run initializers... anything that will need to use or subscribe to the store
    Initializers(store);

    // clear login/logout errors that may be in local storage
    store.dispatch(ClearErrors());
};

initSentry();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    // Following recommendation here: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#popup-apis
    // "When using popup APIs we recommend setting the redirectUri to a blank page or a page that does not implement MSAL. This will help prevent potential issues as well as improve performance."
    // (see the coach-availability-block, MSAL login flow) In our case, redirecting back to the settings page would trigger requests to the API that would then be aborted as the popup window closed.
    // Unclear why a redirect is even needed in the popup flow, given the result of the popup (access token) is received at our calling component regardless of our redirect URI, so long as its on the
    // frontend's origin. As in, the token is transmitted back to us programmatically, not via webhook (purpose of redirection). But there's plenty about Microsoft's Oauth implementation that makes zero sense.
    window.location.pathname === '/ms-auth-redirect' || window.location.pathname === '/calendly-auth-redirect' ? (
        <div />
    ) : (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistedStore} onBeforeLift={onBeforeLift}>
                <BrowserRouter>
                    <GAListener />
                    <Sentry.ErrorBoundary fallback={() => <ErrorFallback />}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <ScrollToTop />
                            <App store={store} />
                        </LocalizationProvider>
                    </Sentry.ErrorBoundary>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    ),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
