import { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ResetAPIStatus } from '../actions/app';
import useSnackbar from '../utils/use-snackbar';
import { getAPIStatus } from '../selectors/app';
import NotificationSnackbar from '../components/notification-snackbar';
import withRoot from '../wiring/with-root';
import themer from '../styles/material-theme';

import InitializerUser from '../initializers/user';
import InitializerUsers from '../initializers/users';
import InitializerFirms from '../initializers/firms';
import InitializerEngagements from '../initializers/engagements';
import InitializerResources from '../initializers/resources';
import PublicLayout from '../layouts/public';
import Constants from '../constants';
import { isAuthenticated } from '../selectors/auth';
import { getCookiesAnswered, getUserPrivs } from '../selectors/user';
import { SentryRoutes } from '../utils/sentry';
import CookiesBanner from '../components/cookies-banner';

const AuthenticatedLayout = lazy(() => import('../layouts/authenticated'));

const App = ({ store }) => {
    const dispatch = useDispatch();
    const isUserSignedIn = useSelector(isAuthenticated);
    const cookiesAnswered = useSelector(getCookiesAnswered);

    const [hydrationComplete, setHydrationComplete] = useState(false);

    const userPrivs = useSelector(getUserPrivs);

    const currentUrl = encodeURIComponent(`${window.location.pathname}${window.location.search}`);

    const apiStatus = useSelector(getAPIStatus);
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();

    useEffect(() => {
        if (isUserSignedIn && !hydrationComplete) {
            InitializerUser(store);
            InitializerResources(store);
            InitializerUsers(store);
            if (userPrivs.getFirms) InitializerFirms(store);
            if (userPrivs.getEngagements) InitializerEngagements(store);
            setHydrationComplete(true);
        }
    }, [isUserSignedIn, hydrationComplete]);

    useEffect(() => {
        if (apiStatus.completed) {
            if (apiStatus.error && apiStatus.errorMsg)
                handleOpenSnackbar({
                    text: apiStatus.errorMsg,
                    type: 'error',
                    autoHideDisabled: apiStatus.autoHideDisabled,
                });
            else if (!apiStatus.error && apiStatus.successMsg)
                handleOpenSnackbar({
                    text: apiStatus.successMsg,
                    type: 'success',
                    autoHideDisabled: apiStatus.autoHideDisabled,
                });
            dispatch(ResetAPIStatus());
        }
    }, [apiStatus, handleOpenSnackbar, ResetAPIStatus]);

    return (
        <Suspense fallback={<div />}>
            <NotificationSnackbar {...snackbarProps} />
            <SentryRoutes>
                <Route
                    path={`/${Constants.protectedDirectory}/*`}
                    element={isUserSignedIn ? <AuthenticatedLayout /> : <Navigate to={`/login?next=${currentUrl}`} />}
                />
                <Route path="/*" element={<PublicLayout />} />
            </SentryRoutes>
            {/* only show the cookies banner if the user has not answered yet */}
            {cookiesAnswered === null && <CookiesBanner />}
        </Suspense>
    );
};

App.propTypes = {
    store: PropTypes.object.isRequired,
};

export default withRoot(App, themer);
