import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { GA4React } from 'ga-4-react';
import { getCookiesAnswered, getUser } from '../../selectors/user';

let ga = null;
const debug = require('debug')('tandem:use-google-analytics');

const MEASUREMENT_ID = process.env.REACT_APP_GA4;

const initGA = async () => {
    if (!ga) {
        const ga4react = new GA4React(MEASUREMENT_ID);
        ga = await ga4react.initialize();
        debug('initialized ga');
    }
};

export const isGAInitialized = () => !!ga;

export const useGoogleAnalytics = () => {
    const cookiesAnswered = useSelector(getCookiesAnswered);
    const currentUser = useSelector(getUser);

    useEffect(() => {
        if (cookiesAnswered) {
            initGA().then(() => {
                if (window.gtag) {
                    const properties = {
                        firmId: currentUser.firmId,
                        userType: currentUser.type,
                        lawFirm: currentUser.lawFirm,
                        isMSAuthenticated: currentUser.isMSAuthenticated,
                        isCalendlyAuthenticated: currentUser.isCalendlyAuthenticated,
                    };
                    debug('set user properties', properties, currentUser);
                    window.gtag('set', 'user_properties', properties);
                }
            });
        }
    }, [cookiesAnswered, currentUser]);

    function trackEvent(name, params) {
        if (ga) {
            ga.event(name, params);
        }
    }

    function trackPageView(path, search, title) {
        debug('TRACKING PAGE VIEW', path, search, title);
        if (ga) {
            ga.pageview(path, search, title);
        }
    }

    return {
        trackEvent,
        trackPageView,
        isGAInitialized,
    };
};
