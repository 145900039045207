import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useGoogleAnalytics } from './use-google-analytics';

export const GAListener = () => {
    const location = useLocation();

    const { trackPageView } = useGoogleAnalytics();

    useEffect(() => {
        trackPageView(location.pathname + location.search);
    }, [location]);

    return null;
};

export default GAListener;
