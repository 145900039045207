import WebClient from '../utils/web-client';
import { setSentryUser } from '../utils/sentry';

const internals = {
    lastToken: null,
    lastUserId: null,
};

export default (store) => {
    internals.maintainAuthToken(store);

    return store.subscribe(() => {
        internals.maintainAuthToken(store);
        internals.maintainSentryUser(store);
    });
};

internals.maintainAuthToken = (store) => {
    const { auth } = store.getState();
    const newToken = (auth.isAuthenticated && auth.credentials && auth.credentials.token) || null;

    if (newToken === internals.lastToken) {
        return;
    }

    WebClient.updateAuth(newToken);

    internals.lastToken = newToken;
};

internals.maintainSentryUser = (store) => {
    const { user } = store.getState();
    if (user.id === internals.lastUserId) {
        return;
    }
    setSentryUser(user);
    internals.lastUserId = user.id;
};
