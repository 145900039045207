import WebClient from '../utils/web-client';
import AuthTypes from '../action-types/auth';
import UserTypes from '../action-types/user';
import { APIError, APISuccess } from './app';
import { setSentryUser } from '../utils/sentry';

export const ClearErrors = () => ({
    type: AuthTypes.INITIALIZE_APP,
});

export const LoginBegin = () => ({
    type: AuthTypes.LOGIN_BEGIN,
});
const LoginSuccess = () => ({
    type: AuthTypes.LOGIN_SUCCESS,
});
const LoginError = (errorMsg) => ({
    type: AuthTypes.LOGIN_ERROR,
    payload: { errorMsg },
});
export const SetupUser = (user) => ({
    type: UserTypes.SETUP_USER,
    payload: user,
});

export const LoginConfirmationBegin = () => ({
    type: AuthTypes.LOGIN_CONFIRMATION_BEGIN,
});
const LoginConfirmationSuccess = (token) => ({
    type: AuthTypes.LOGIN_CONFIRMATION_SUCCESS,
    payload: { token },
});
const LoginConfirmationError = (errorMsg) => ({
    type: AuthTypes.LOGIN_CONFIRMATION_ERROR,
    payload: { errorMsg },
});

export const LoginConfirmation = (proofToken, auth2FAToken, onSuccess, onError) => async (dispatch) => {
    dispatch(LoginConfirmationBegin());
    try {
        const { data } = await WebClient.post('/login/two-factor', {
            proofToken,
            token: auth2FAToken,
        });

        setSentryUser(data.user);

        // update auth token
        WebClient.updateAuth(data.token);

        dispatch(SetupUser(data.user));
        dispatch(LoginConfirmationSuccess(data.token));
        if (onSuccess) onSuccess(data.token);
    } catch (error) {
        let errorType = 'UNKNOWN';
        if (error.response && error.response.status === 401) {
            errorType = 'UNAUTHORIZED';
        }
        if (error.response && error.response.status === 422) {
            errorType = 'UNPROCESSABLE';
        }
        dispatch(LoginConfirmationError(errorType));
        if (onError) onError(errorType);
    }
};

export const Login = (formValues, onSuccess, onError) => async (dispatch) => {
    const { email, password } = formValues;

    dispatch(LoginBegin());
    try {
        const { data } = await WebClient.post('/login', {
            email,
            password,
        });
        dispatch(LoginSuccess());
        // if the pw is expired, we use the proof token as a temporary jwt
        if (data.expired) WebClient.updateAuth(data.proofToken);
        if (onSuccess)
            onSuccess({
                proofToken: data.proofToken,
                expired: data.expired,
            });
    } catch (error) {
        let errorType = 'UNKNOWN';
        if (error.response && error.response.status === 401) {
            errorType = 'UNAUTHORIZED';
        }
        if (error.response && error.response.status === 422) {
            errorType = 'UNPROCESSABLE';
        }
        dispatch(LoginError(errorType));
        if (onError) onError(errorType);
    }
};

const LogoutInternals = () => ({
    type: AuthTypes.LOGOUT,
});

export const Logout = () => async (dispatch) => {
    try {
        await WebClient.post('/logout');
        setSentryUser(null);
        dispatch(LogoutInternals());
    } catch (error) {
        dispatch(LogoutInternals());
    }
};

export const ForgotPassBegin = () => ({
    type: AuthTypes.FORGOT_PASSWORD_BEGIN,
});
const ForgotPassSuccess = () => ({
    type: AuthTypes.FORGOT_PASSWORD_SUCCESS,
});
const ForgotPassError = (errorMsg) => ({
    type: AuthTypes.FORGOT_PASSWORD_ERROR,
    payload: { errorMsg },
});
export const ForgotPass = (email, onSuccess, onError) => async (dispatch) => {
    dispatch(ForgotPassBegin());

    try {
        await WebClient.post('/users/request-reset', { email });
        dispatch(ForgotPassSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (error.response.status === 404 || error.response.status === 400)) {
            errorType = 'NOT_FOUND';
        }

        dispatch(ForgotPassError(errorType));
        if (onError) onError(errorType);
    }
};

export const ResetPassBegin = () => ({
    type: AuthTypes.RESET_PASSWORD_BEGIN,
});
const ResetPassSuccess = () => ({
    type: AuthTypes.RESET_PASSWORD_SUCCESS,
});
const ResetPassError = (errorMsg) => ({
    type: AuthTypes.RESET_PASSWORD_ERROR,
    payload: { errorMsg },
});
export const ResetPass = (formValues, onSuccess, onError) => async (dispatch) => {
    dispatch(ResetPassBegin());

    const { email, resetToken, password } = formValues;

    try {
        await WebClient.post('/users/reset-password', {
            email,
            newPassword: password,
            resetToken,
        });
        dispatch(ResetPassSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (error.response.error === 404 || error.response.error === 400)) {
            errorType = 'NOT_FOUND';
        }

        dispatch(ResetPassError(errorType));
        if (onError) onError(errorType);
    }
};

export const SetPassBegin = () => ({
    type: AuthTypes.SET_PASSWORD_BEGIN,
});
const SetPassSuccess = () => ({
    type: AuthTypes.SET_PASSWORD_SUCCESS,
});
const SetPassError = (errorMsg) => ({
    type: AuthTypes.SET_PASSWORD_ERROR,
    payload: { errorMsg },
});
export const SetPass = (formValues, onSuccess, onError) => async (dispatch) => {
    dispatch(SetPassBegin());

    const { email, inviteToken, password } = formValues;

    try {
        const { data } = await WebClient.post('/users/set-password', {
            email,
            newPassword: password,
            inviteToken,
        });
        dispatch(SetPassSuccess());
        dispatch(APISuccess('Please look for an email for your 2FA code.'));
        if (onSuccess) onSuccess(data.proofToken);
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (error.response.error === 404 || error.response.error === 400)) {
            errorType = 'NOT_FOUND';
        }
        if (error.response.data.message === 'Invite has already been accepted') {
            if (onSuccess) onSuccess(); // redirects to index page
            dispatch(
                APIError(
                    "Our records indicate that an account has successfully been created for this email address.  Please login as usual.  If you need to reset your password, please click 'Forgot Password'",
                ),
            );
        } else {
            dispatch(APIError('There was an error. Please try again.'));
        }
        dispatch(SetPassError(errorType));
        if (onError) onError(errorType);
    }
};

export const UpdatePassBegin = () => ({
    type: AuthTypes.UPDATE_PASSWORD_BEGIN,
});
const UpdatePassSuccess = () => ({
    type: AuthTypes.UPDATE_PASSWORD_SUCCESS,
});
const UpdatePassError = (errorMsg) => ({
    type: AuthTypes.UPDATE_PASSWORD_ERROR,
    payload: { errorMsg },
});
export const UpdatePass = (formValues, onSuccess, onError) => async (dispatch) => {
    dispatch(UpdatePassBegin());

    const { password, newPassword } = formValues;

    try {
        const { data } = await WebClient.post('/users/change-password', {
            password,
            newPassword,
        });
        dispatch(UpdatePassSuccess());
        dispatch(APISuccess('Password successfully updated.'));
        if (onSuccess) onSuccess(data.proofToken);
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && error.response.error === 401) {
            errorType = 'MISSING_AUTH';
        } else if (error?.response?.data?.errors.error[0] === 'Invalid Password') {
            errorType = 'INVALID_PASSWORD';
        } else if (error?.response?.data?.errors.error[0] === 'New password must be different from old password') {
            errorType = 'MUST_BE_UNIQUE';
        }

        dispatch(UpdatePassError(errorType));
        if (onError) onError(errorType);
    }
};

const CheckTokenBegin = () => ({
    type: AuthTypes.CHECK_TOKEN_BEGIN,
});
const CheckTokenSuccess = () => ({
    type: AuthTypes.CHECK_TOKEN_SUCCESS,
});
const CheckTokenError = () => ({
    type: AuthTypes.CHECK_TOKEN_ERROR,
});

export const CheckToken = () => async (dispatch) => {
    dispatch(CheckTokenBegin());

    try {
        await WebClient.get('/users/authenticated');
        dispatch(CheckTokenSuccess());
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // if 401, log them out cause the token is invalid
            dispatch(CheckTokenError());
            dispatch(Logout());
        } else if (error.response && error.response.status === 403) {
            // 403 is a scope error, their token is valid but their password has expired and therefore they need to reset it
            dispatch(CheckTokenError());
            dispatch(Logout());
        }
        // do nothing with other errors
    }
};

const Resend2FACodeBegin = () => ({
    type: AuthTypes.RESEND_2FA_BEGIN,
});
const Resend2FACodeSuccess = () => ({
    type: AuthTypes.RESEND_2FA_SUCCESS,
});
const Resend2FACodeError = () => ({
    type: AuthTypes.RESEND_2FA_ERROR,
});

export const Resend2FACode = (proofToken, onSuccess, onError) => async (dispatch) => {
    dispatch(Resend2FACodeBegin());
    try {
        await WebClient.post('/login/resend-code', { proofToken });
        if (onSuccess) onSuccess();
        dispatch(Resend2FACodeSuccess());
    } catch (error) {
        if (onError) onError();
        dispatch(Resend2FACodeError());
    }
};
