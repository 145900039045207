import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

export const init = () => {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENV,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
            new CaptureConsoleIntegration(),
        ],
        tracesSampleRate: 0.1,
    });
};

export const setSentryUser = (user) => {
    if (!user) {
        return Sentry.setUser(null);
    }
    return Sentry.setUser({
        id: user.id,
        firmId: user.firmId,
    });
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
